import React, { useEffect } from "react";
import defaultVariant from "../../../script/default_variant";
import themeAsset from "../../../script/theme_asset";
import activeGroup from "../../../script/active_group";
import multiVariant from "../../../script/multivariant";

const MainSection = ({ mainResult, loading, query }) => {
  useEffect(() => {
    activeGroup();
    defaultVariant();
    themeAsset();
    multiVariant();
  }, [mainResult, loading]);

  // console.log("result", mainResult);

  let search_string = query?.replace(/^https?:\/\/(www\.)?/, "");
  search_string = search_string?.split("/")[0];

  return (
    <section className=" main-section col-12">
      {loading ? (
        <div>
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
          <div className="text-center mt-2">Loading top 100 results...</div>
        </div>
      ) : (
        <div className="container-fluid mb-3 mt-3">
          {mainResult && mainResult.length > 0
            ? mainResult.map((result, index) => {
                if (result.data && result.data.length > 0) {
                  return (
                    <div key={result.short_name} className="extra">
                      <h2 className="text-muted mb-2">{result.app}</h2>
                      <div style={{ overflow: "auto" }} className="mb-2">
                        <table className="table table-responsive table-hover">
                          <thead className="bg-dark text-light">
                            <tr>
                              <th scope="col"></th>
                              <th scope="row">ID</th>
                              <th scope="col">Shopify Domain</th>
                              <th scope="col">Email</th>
                              <th scope="col">Dates</th>
                              <th scope="col">Usage</th>
                              <th scope="col">Info</th>
                              <th scope="col">Links</th>
                            </tr>
                          </thead>
                          <tbody>
                            {result.data.map((data, dataIndex) => (
                              <TableRow
                                key={data.created_at + data.shopify_domain}
                                data={data}
                                result={result}
                              />
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  );
                }
              })
            : null}
          {mainResult &&
            mainResult.reduce((acc, currVal) => {
              if (currVal.data.length) {
                acc = false;
              }
              return acc;
            }, true) && (
              <div>
                <h2 className="text-muted">Record not found!!</h2>
                <table className="table table-responsive table-hover">
                  <thead className="bg-dark text-light">
                    <tr>
                      <th scope="col">{search_string}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{search_string}</td>
                    </tr>
                    <tr
                      className={`shopify_domain ${search_string}`}
                      data-domain={search_string}
                      style={{ display: "none" }}
                    >
                      <td className="products"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
        </div>
      )}
    </section>
  );
};

export default MainSection;

const TableRow = ({ data, result }) => {
  // functionine background class logic
  let bgClass = "bg-info";
  if (!data.installed || isInactivePlan(data.shopify_plan)) {
    bgClass = "bg-danger";
  } else if (data.installed && ["active", "paused"].includes(data.status)) {
    bgClass = "bg-success";
  }

  const showClass = () => {
    if (
      data.installed &&
      !["product_title_king", "alt_text_king"].includes(result.app_code) &&
      isPaidPlan(data.shopify_plan) &&
      !["active", "paused"].includes(data.status)
    ) {
      return data.status === "unsubscribed" ? "bg-unsubscribed" : "bg-warning";
    }
    return "";
  };

  return (
    <>
      <tr>
        <td className={bgClass}></td>
        <th
          className={showClass()}
          data-toggle="tooltip"
          title={
            showClass() == "unsubscribed"
              ? "plan unsubscribed"
              : showClass() == "bg-warning"
              ? "plan not active"
              : ""
          }
        >
          <a
            href={`${result.url}/shops/${data.shop_id}`}
            target="_blank"
            rel="noreferrer"
            className="d-block mb-1"
          >
            {data.shop_id}
          </a>
          <a
            href={`${result.url}/shops/${data.shop_id}/edit`}
            target="_blank"
            rel="noreferrer"
            className="d-block mb-1"
          >
            Edit
          </a>
          <a
            href={`${result.url}/shops/${data.shop_id}/dashboard?shop=${data.shopify_domain}`}
            target="_blank"
            rel="noreferrer"
            className="d-block mb-1"
          >
            Dashboard
          </a>
          {data.installed &&
            !["product_title_king", "alt_text_king"].includes(
              result.app_code
            ) && (
              <a
                href={`${result.url}/shops/${data.shop_id}/show`}
                target="_blank"
                rel="noreferrer"
                className="d-block mb-1"
              >
                Credit
              </a>
            )}
        </th>

        <td>
          <a
            href={`https://${data.shopify_domain}`}
            target="_blank"
            rel="noreferrer"
            className="d-block mb-1"
          >
            {data.shopify_domain.replace(".myshopify.com", "")}
          </a>
          {data.store_domain && data.store_domain !== data.shopify_domain && (
            <a
              href={`https://${data.store_domain}`}
              target="_blank"
              rel="noreferrer"
              className="d-block mb-1"
            >
              {data.store_domain}
            </a>
          )}
          {data.shop_name && <p className="d-block mb-1">{data.shop_name}</p>}
          <button
            className="btn btn-sm copy-btn"
            data-clipboard-text={data.shopify_domain}
          >
            Copy
          </button>
          <a
            href={`https://${data.shopify_domain}/collections/all`}
            target="_blank"
            rel="noreferrer"
            className="btn btn-sm mt-1"
          >
            Collection/all
          </a>
          <a
            className="btn btn-sm mt-1"
            href={`/get_store_details/${result?.app_code}/${data?.shop_id}`}
            target="_blank"
            rel="noreferrer"
          >
            Permissions and more
          </a>
          {data.markets_count ? (
            <React.Fragment>
              <p>{data.markets_count} Markets</p>
              <p>{data.market_urls_count} Languages</p>
            </React.Fragment>
          ) : null}
        </td>

        <td>
          <div className="d-block mb-1">
            <div className="d-flex justify-content-start align-items-center">
              <a
                href={`https://starappsassist.freshdesk.com/a/search/tickets?term=${data.email}`}
                target="_blank"
                rel="noreferrer"
                title="Fresh desk ticket"
              >
                {data.email}
              </a>
              <button
                className="copy-btn remove"
                data-clipboard-text={data.email}
                data-original-title=""
              >
                <i className="far fa-copy"></i>
              </button>
            </div>
            <a
              href={`/unsubscribe?email=${data.email}`}
              target="_blank"
              rel="noreferrer"
              title="Unsubscribe from mailing list"
            >
              Unsubscribe
            </a>
          </div>
          <div className="d-block mb-1">
            {data.customer_email && data.customer_email !== data.email && (
              <div className="d-flex justify-content-start align-items-center">
                <a
                  href={`https://starappsassist.freshdesk.com/a/search/tickets?term=${data.customer_email}`}
                  target="_blank"
                  rel="noreferrer"
                  title="Fresh desk ticket"
                >
                  {data.customer_email}
                </a>
                <button
                  className="copy-btn remove"
                  data-clipboard-text={data.customer_email}
                  data-original-title=""
                >
                  <i className="far fa-copy"></i>
                </button>
              </div>
            )}
            {data.customer_email && data.customer_email !== data.email && (
              <a
                href={`/unsubscribe?email=${data.customer_email}`}
                target="_blank"
                rel="noreferrer"
                title="Unsubscribe from mailing list"
              >
                Unsubscribe
              </a>
            )}
          </div>
          <p className="d-block mb-1">{data.shop_owner}</p>
        </td>

        <td>
          <p className="d-block mb-1">
            Created:{" "}
            {data.store_created_at
              ? new Date(data.store_created_at).toLocaleDateString("en-GB")
              : "N/A"}
          </p>
          <p className="d-block mb-1">
            Install:{" "}
            {data.install_date
              ? new Date(data.install_date).toLocaleDateString("en-GB")
              : "N/A"}
          </p>
          <p className="d-block mb-1">
            Activation:{" "}
            {data.plan_activation_date
              ? new Date(data.plan_activation_date).toLocaleDateString("en-GB")
              : "N/A"}
          </p>
          {data.uninstall_date && (
            <p className="d-block mb-1">
              Uninstall:{" "}
              {new Date(data.uninstall_date).toLocaleDateString("en-GB")}
            </p>
          )}
        </td>

        <td>
          <a
            href={`${result.url}/shops/${data.shop_id}/show_usage`}
            target="_blank"
            rel="noreferrer"
            className="d-block mb-1"
          >
            {data.rule_name}
          </a>
          <p className="d-block mb-1">{getRemainingTrialDays(data)}</p>
          {parseInt(data.discount_percentage) !== 0 && (
            <span className="d-block mb-1">
              Discount: {data.discount_percentage}%
            </span>
          )}
          {!["product_title_king", "alt_text_king"].includes(
            result.app_code
          ) && (
            <>
              {parseInt(data.annual_discount) !== 0 && (
                <span className="d-block mb-1">
                  Annual Discount: {data.annual_discount}%
                </span>
              )}
              {parseInt(data.onetime_discount) !== 0 && (
                <span className="d-block mb-1">
                  Onetime Discount: {data.onetime_discount}%
                </span>
              )}
            </>
          )}
          {data.product_count === null ? (
            <span className="d-block mb-1">No Product</span>
          ) : (
            <span className="d-block mb-1">
              Product Count: {data.product_count}
            </span>
          )}
          {result.app_code === "variant_swatch_king" && (
            <>
              {parseInt(data.product_groups_count) !== 0 ? (
                <span className="d-block mb-1">
                  Group Count: {data.product_groups_count}
                </span>
              ) : (
                <span className="d-block mb-1">No Group</span>
              )}
            </>
          )}
        </td>

        <td>
          <p className="d-block mb-1">
            <span
              className={`${
                isInactivePlan(data.shopify_plan) && "bg-store-closed"
              }`}
            >
              <b>
                {data.plan_display_name
                  ? data.plan_display_name
                  : data.shopify_plan}
              </b>
            </span>
          </p>
          <p className="d-block mb-1 star-rating">
            {data.has_reviewed ? "★★★★★" : ""}
          </p>
          <p className="d-block mb-1">{data.enable ? "Enabled" : "Disabled"}</p>
          <div className="d-flex justify-content-start align-items-center">
            <p className="d-block mb-1">
              {data.password_enabled ? "password_enabled" : ""}
            </p>
            {data.store_password && (
              <button
                title={data.store_password}
                className="btn btn-sm copy-btn remove"
                data-clipboard-text={data.store_password}
                data-original-title=""
              >
                <i className="far fa-copy"></i>
              </button>
            )}
          </div>
          {data.app_version === null ? (
            <span className="d-block mb-1"></span>
          ) : (
            <span className="d-block mb-1">
              App Version: {data.app_version}
            </span>
          )}
          {result.app_code === "variant_swatch_king" && (
            <>
              {data.main_theme ? (
                <span
                  className="badge badge-success pointer"
                  data-placement="top"
                  tabIndex="0"
                  data-toggle="tooltip"
                  data-mdb-custom-class="text-sm"
                  title={data.main_theme}
                >
                  Active on main
                </span>
              ) : null}
              {data.unpublished_theme_count != 0 ? (
                <span
                  className="badge badge-warning"
                  style={{ fontSize: "11px" }}
                >
                  Unpublished ({data.unpublished_theme_count})
                </span>
              ) : null}
              {data.theme_setting_count == 0 ? (
                <span className="badge badge-danger">Never used</span>
              ) : null}
            </>
          )}
          {result.app_code === "variant_description_king" &&
            (() => {
              if (data.theme_ids) {
                const themeIds = JSON.parse(data.theme_ids);
                const unpublishedThemes = themeIds.filter(
                  (theme) =>
                    (!theme.role || theme.role.toLowerCase() !== "main") &&
                    theme.status.toLowerCase() === "enabled"
                );
                const mainTheme = themeIds.find(
                  (theme) =>
                    theme.role &&
                    theme.role.toLowerCase() === "main" &&
                    theme.status.toLowerCase() === "enabled"
                );

                return (
                  <>
                    {mainTheme && (
                      <span
                        className="badge badge-success pointer"
                        data-placement="top"
                        tabIndex="0"
                        data-toggle="tooltip"
                        data-mdb-custom-class="text-sm"
                        title={mainTheme.name}
                      >
                        Active on main
                      </span>
                    )}
                    {themeIds.length === 0 && (
                      <span className="badge badge-danger">Never used</span>
                    )}
                    {unpublishedThemes.length > 0 && (
                      <span
                        className="badge badge-warning"
                        style={{ fontSize: "11px" }}
                      >
                        Unpublished ({unpublishedThemes.length})
                      </span>
                    )}
                    <div className="d-block">
                      {!data.allow_multiple_descriptions && (
                        <span className="badge badge-danger">
                          Multiple description: off
                        </span>
                      )}
                      {data.app_version <= 3 && (
                        <span className="badge badge-warning">
                          App version: {data.app_version}
                        </span>
                      )}
                      {data.accordion_selector && (
                        <div>
                          <span className="badge badge-dark">
                            Heading: {data.accordion_selector}
                          </span>
                        </div>
                      )}
                    </div>
                  </>
                );
              }
            })()}
          {result.app_code === "variant_image_automator" && (
            <>
              {data.limit_on_product !== "none" && (
                <span className="d-block mb-1">
                  limit_on_product:{" "}
                  <span className="bg-danger">{data.limit_on_product}</span>
                </span>
              )}
            </>
          )}
        </td>

        <td>
          {result.app_code === "variant_image_automator" && (
            <p className="d-block mb-1">
              {data.theme_name ? (
                data.theme_name
              ) : (
                <span className="bg-danger">Not Selected</span>
              )}
            </p>
          )}
          {data.installed && (
            <a
              href={`/themes/${result.app_code}/${data.shop_id}`}
              target="_blank"
              rel="noreferrer"
              className="d-block mb-1"
            >
              Get Themes
            </a>
          )}
          {data.shopify_store_id && (
            <a
              href={`https://partners.shopify.com/499035/stores/${data.shopify_store_id}`}
              target="_blank"
              rel="noreferrer"
              className="d-block mb-1"
            >
              Partners Page
            </a>
          )}
          {result.app_code === "variant_swatch_king" ? (
            <a
              href={`${result.url}/admin/code?shopify_domain=${data.shopify_domain}`}
              target="_blank"
              rel="noreferrer"
              className="d-block mb-1"
            >
              Get Code
            </a>
          ) : (
            <a
              href={`${result.url}/admin/script/${data.shopify_domain}`}
              target="_blank"
              rel="noreferrer"
              className="d-block mb-1"
            >
              Get Code
            </a>
          )}
          {data.intercom_url && (
            <a href={data.intercom_url} target="_blank">
              Intercom
            </a>
          )}
        </td>
      </tr>
      {JSON.parse(data?.product_links) &&
      Object.keys(JSON.parse(data?.product_links)).length > 0 ? (
        <tr
          className={`shopify_domain ${result.short_name} ${data.shopify_domain}`}
          data-domain={data.shopify_domain}
          data-app_name={result.short_name}
          data-settings={
            result.short_name === "VDK" ? data.accordion_selector : ""
          }
          data-run-script={
            JSON.parse(data?.product_links) &&
            Object.keys(JSON.parse(data?.product_links)).length > 0
              ? false
              : true
          }
        >
          <td className={bgClass}></td>
          <RenderProductLinks data={data.product_links} />
        </tr>
      ) : data.password_enabled ? null : (
        <tr
          className={`shopify_domain ${result.short_name} ${data.shopify_domain}`}
          data-domain={data.shopify_domain}
          data-app_name={result.short_name}
          data-settings={
            result.short_name === "VDK" ? data.accordion_selector : ""
          }
          data-run-script={
            JSON.parse(data?.product_links) &&
            Object.keys(JSON.parse(data?.product_links)).length > 0
              ? false
              : true
          }
        >
          <td className={bgClass}></td>
          <td colSpan="10" className="products"></td>
        </tr>
      )}
    </>
  );
};

function isPaidPlan(shopify_plan) {
  return [
    "dormant",
    "basic",
    "professional",
    "business",
    "custom",
    "unlimited",
    "shopify_plus",
    "npo_lite",
    "npo_full",
  ].includes(shopify_plan);
}

function affiliate(shopify_plan) {
  return ["affiliate", "partner_test", "plus_partner_sandbox"].includes(
    shopify_plan
  );
}

function isStaff(shopify_plan) {
  return [
    "staff",
    "staff_business",
    "shopify_alumni",
    "sales_training",
    "shopify_trial_free_plan",
    "shopify_staff_free_plan",
    "open_learning",
  ].includes(shopify_plan);
}

function isTrialStore(shopify_plan) {
  return shopify_plan == "trial";
}

function isInactivePlan(shopify_plan) {
  return ["cancelled", "frozen", "closed", "fraudulent", "paused"].includes(
    shopify_plan
  );
}

function getRemainingTrialDays(data) {
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const planActivationDate = data.plan_activation_date
    ? new Date(data.plan_activation_date)
    : new Date();
  const uninstall_date = data.uninstall_date
    ? new Date(data.uninstall_date)
    : new Date();
  const diffInMilliseconds = uninstall_date - planActivationDate;
  const dayUsed = Math.ceil(diffInMilliseconds / millisecondsPerDay);

  const trialDays = parseInt(data.trial_days) || 0;
  return dayUsed >= trialDays
    ? "Trial Over"
    : `${trialDays - dayUsed} day trial left`;
}

function RenderProductLinks({ data }) {
  const parsed_data = JSON.parse(data);
  return (
    <td colSpan="10" className="products">
      <div>
        {Object.keys(parsed_data).map((name, idx) => {
          return (
            <div style={{ margin: "2px" }} key={idx}>
              <a
                href={parsed_data[name]}
                target="_blank"
                rel="noreferrer"
                className="d-inline-block mb-1"
              >
                {name}
              </a>
              <button
                className="btn btn-sm copy-btn d-inline-block"
                data-clipboard-text={parsed_data[name]}
              >
                Copy link
              </button>
            </div>
          );
        })}
      </div>
    </td>
  );
}
